@use '@fagforbundet/ngx-components' as ff;

@use 'containers';

@import 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap';

@include ff.reset();

@include ff.base();

body {
}

html:has(dialog[open]) {
  overflow: hidden;
}
