@use '../../abstracts/borders';
@use '../../abstracts/colors/palette';
@use '../../abstracts/colors/semantic/border' as borderColors;
@use '../../abstracts/colors/semantic/form';
@use '../../abstracts/colors/semantic/text';
@use '../../utilities/outline';
@use './control-error';

@mixin input-reset() {
  background: none;
  border: none;
  border-radius: 0;
  height: 28px;
  min-width: 0;
  padding: 0;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
}

@mixin textarea-reset() {
  background: none;
  border: none;
  border-radius: 0;
  height: auto;
  min-width: 0;
  padding: 0;
  resize: vertical;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
}

@mixin input-field($padding: 10px) {
  background-color: palette.$ff-white-100;
  border: 1px solid borderColors.$ff-border-clr-120;
  border-radius: borders.$ff-border-radius-default;
  box-sizing: border-box;
  color: text.$ff-text-clr-100;
  line-height: 30px;
  padding: $padding;
  width: 100%;

  &[type='text'] {
    line-height: 30px;
  }

  &.secondary {
    background-color: transparent;
    cursor: pointer;
  }

  &:hover {
    border-color: palette.$ff-gray-120;
  }

  &::placeholder,
  .placeholder {
    color: form.$ff-form-placeholder;
  }

  &:focus-visible,
  &:focus,
  &:has(input:focus-visible) {
    @include outline.focused-red;
  }

  &[readonly],
  &:has(input[readonly]) {
    background-color: palette.$ff-sand-70;
    cursor: default;
  }

  &:disabled,
  &[disabled],
  &.disabled,
  &:has(input[disabled]) {
    background-color: form.$ff-form-input-disabled-bg;
    border-color: borderColors.$ff-border-clr-100;
    cursor: not-allowed;

    &:focus {
      outline: none;
    }
  }

  @include control-error.outline;

  &.dropdown-input-field {
    &.panel-open {
      @include outline.focused-box-shadow;
      border: 1px solid text.$ff-text-clr-50;
      border-radius: borders.$ff-border-radius-default
        borders.$ff-border-radius-default 0 0;
      outline: none;

      &.transparent {
        border: none;
        border-radius: 0;
        box-shadow: none;
      }
    }
  }

  &.transparent {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0;

    &:focus-visible,
    &:has(input:focus-visible) {
      box-shadow: none;
      outline: none;
    }
  }
}

@mixin private-input-styles() {
  .ff-input {
    display: block;

    @include input-field;
  }
}

@mixin textarea() {
  display: block;
  min-height: 100px;
  resize: vertical;

  @include input-field;
}

@mixin private-textarea-styles() {
  .ff-textarea {
    @include textarea;
  }
}
